import React, { useState } from "react";
import { Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

export const InternalApiValidator = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  const { getAccessTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log("Token obtained:", token);

      const response = await fetch('https://authvalidator.fmac.workers.dev/', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'text/html', // Expecting HTML
        },
      });

      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.text(); // Receive the raw HTML
      console.log("Response data:", responseData);

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData, // Store the HTML
      });
    } catch (error) {
      console.error("Error during API call:", error);
      setState({
        ...state,
        error: error.message,
      });
    }
  };

  return (
    <>
      <div className="mb-5">
        <h1>Internal API Validator</h1>
        <p className="lead">
          Use the button below to validate your JWT.
        </p>

        <Button
          color="primary"
          className="mt-5"
          onClick={callApi}
        >
          Ping API
        </Button>
      </div>

      <div className="result-block-container">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <div dangerouslySetInnerHTML={{ __html: state.apiMessage }} /> {/* Render the iframe */}
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(InternalApiValidator, {
  onRedirecting: () => <Loading />,
});
